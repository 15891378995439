import { useEffect, useRef, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const ShowInvalidReason = ({ reasons }) => {
	const [showPopup, setShowPopup] = useState(false);
	const componentRef = useRef(null);

	const closePopup = (e) => {
		e.stopPropagation();
		setShowPopup(prev => !prev);
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
		  if (componentRef.current && !componentRef.current.contains(event.target)) {
			setShowPopup(false);
		  }
		};
	
		document.addEventListener('mousedown', handleClickOutside);
	
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, []);

	return (
		<p className="text-center text-red-800 text-lg flex items-center gap-4 relative" ref={componentRef}>
			Invalid <AiOutlineExclamationCircle className='inline text-gray-200 text-white cursor-pointer' onClick={closePopup}/>

			{
				showPopup && 
				<div className="flex flex-col gap-3 bg-white w-64 p-4 rounded-lg z-10 absolute right-0 top-0 text-black text-left shadow-lg" style={{ transform: "translateX(17rem)" }} onClick={(e) => {closePopup(e);}}>
					{
						reasons.map((reason, i) => (
							<div className="flex gap-2" key={i}>
								<p className="text-sm font-thin">{reason}</p>
							</div>
						))
					}
                </div>
			}
		</p>
	);
}

export default ShowInvalidReason;