import { React, useState, useEffect } from "react";

export default function ViewProduct(props) {
  const product = props.product;
  const [disabled, setDisabled] = useState(true);
  const [editHover, setEditHover] = useState(false);
  useEffect(() => {
    if (!props.product.id) {
      setDisabled(false);
    }
  }, [props]);
  return (
    <div className="mt-4 flex items-center">
      <div className="grid grid-cols-12 text-center text-text-3 font-font-2 font-normal">
        <div className="my-auto flex justify-end">
          <div className="flex flex-col leading-none text-text-1">
            <button
              className="mt-1 -m-1 focus:outline-none"
              onClick={() => {
                props.changePriority("products", props.index, -1);
              }}
            >
              ⬆
            </button>
            <button
              className="focus:outline-none"
              onClick={() => {
                props.changePriority("products", props.index, 1);
              }}
            >
              ⬇
            </button>
          </div>
          <div className="mx-2 flex w-8 bg-background-10 text-black font-bold">
            <p className="m-auto">{props.index + 1}</p>
          </div>
        </div>
        <select
          name="products"
          id="products"
          style={{
            border: 0,
            WebkitAppearance: "none",
            appearance: "none",
          }}
          value={product.Tid}
          disabled={disabled}
          className="px-2 py-1 bg-background-6 font-bold"
          onChange={(event) => {
            var proc = [];
            for(var i = 0; i < props.templateProducts.length; i++){
              if(props.templateProducts[i].id === parseInt(event.target.value)){
                proc = props.templateProducts[i];
                break;
              }
            }
            props.setProduct({
              ...product,
              Tid: proc.id,
              fee: proc.fee,
              brand: proc.brand,
              volume: proc.volume,
            });
          }}
        >
          <option value=""></option>
          {props.templateProducts &&
            props.templateProducts.map((proc, i) => {
              return (
                <option
                  key={i}
                  value={proc.id}
                >{`${proc.name}`}</option>
              );
            })}
        </select>
        <input
          type="text"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.brand}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, brand: event.target.value });
          }}
        />
        <input
          type="text"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.volume}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, volume: event.target.value });
          }}
        />
        <input
          type="text"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.number}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, number: event.target.value });
          }}
        />
        <input
          type="text"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.refill}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, refill: event.target.value });
          }}
        />
        <input
          type="number"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.fee}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, fee: event.target.value });
          }}
        />
        {(!props?.product?.has_shipping && (
          <>
            {disabled ? (
              <div
                className="bg-background-7 flex cursor-pointer"
                onMouseOver={() => {
                  setEditHover(true);
                }}
                onMouseOut={() => {
                  setEditHover(false);
                }}
              >
                <svg
                  className="m-auto h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill={editHover ? "#13c3b2" : "#bec4ca"}
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                  />
                </svg>
                <div
                  className={`absolute mt-8 flex flex-col text-sm bg-background-10 text-black ${
                    !editHover && "hidden"
                  }`}
                >
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      setDisabled(false);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      props.duplicateTreatment(props.product.id, "products");
                    }}
                  >
                    Duplicate
                  </button>
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      props.deleteTreatment(props.product.id, "products", props.index);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-background-7 flex">
                <button
                  className="m-auto focus:outline-none"
                  onClick={() => {
                    // props.saveTreatment(product);
                    setDisabled(true);
                  }}
                >
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#13c3b2"
                      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
                    />
                  </svg>
                </button>
              </div>
            )}
          </>
        )) || <div className="bg-background-7 flex"></div>}
        <div className="bg-background-7 flex">
          <button
            className="mx-auto focus:outline-none"
            onClick={() => {
              if (!product.id)
                return alert("Please save treatment first");
              props.setModalConsent({tid: product.id, ...product.consent});
              props.setShowConsent(true)
            }}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={product.consent ? "#13c3b2" : "#bec4ca"}
                d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"
              />
            </svg>
          </button>
        </div>
        <div className="bg-background-7 flex">
          <button className="mx-auto focus:outline-none">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={props.product.paymentCompleted ? "#13c3b2" : "#bec4ca"}
                d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
              />
            </svg>
          </button>
        </div>
        <div className="bg-background-7 flex">
          <button className="mx-auto focus:outline-none">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={props.product.has_shipping ? "#13c3b2" : "#bec4ca"}
                d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
