import { React, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Loading from '../views/Loading';

export default function PatientReferrals() {
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/referrals`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.patients);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`container mx-auto flex flex-col p-4 text-text-2 ${
          ready ? '' : 'hidden'
        }`}
      >
        <nav className="flex pb-6 border-b-2 border-background-2">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
        </nav>
        {message && <p className="mt-4 text-center text-red-600">{message}</p>}
        {patientReferrals && patientReferrals.length > 0 ? (
          <>
            <div className="mx-auto mt-20 grid gap-4">
              <div className="flex items-end justify-between">
                <p className="w-52 text-center text-xl tracking-widest">
                  Affiliate
                </p>
                <p className="w-96 text-xl tracking-widest">
                  Patient Referrals
                </p>
                <div />
              </div>
              <section className="mt-10 items-end grid gap-10">
                {patientReferrals.map(patient => {
                  return (
                    <div
                      key={patient.id}
                      className="flex items-end justify-evenly"
                    >
                      <p className="w-52 text-center">
                        {patient.referral.referred_by}
                      </p>
                      <p className="ml-4 mr-8 p-2 w-96 bg-background-2 text-text-7 rounded-sm flex items-center justify-evenly">
                        <span className="truncate">
                          {patient.firstName} {patient.lastName}
                        </span>
                        <span className="font-normal text-sm">
                          DOB: {patient.dob}
                        </span>
                      </p>
                      <NavLink
                        to={`/patient/presentation/overview/${patient.id}`}
                        className="font-thin tracking-widest h-10 w-32 flex items-center justify-center bg-accent-1 rounded-lg text-sm text-text-3 focus:outline-none"
                      >
                        Review
                      </NavLink>
                    </div>
                  );
                })}
              </section>
            </div>
          </>
        ) : (
          <div className="mx-auto my-auto text-2xl">No Referrals Found</div>
        )}
      </main>
      <Loading message="Loading Patient Referrals" ready={ready} />
    </>
  );
}
