import { React } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";

export default function PatientNavbar(props) {
  return (
    <nav className="flex border-b-2 border-background-7 pb-6 mx-10">
      <NavLink to="/">
        <img className="h-20" src={Logo} alt="GRO Track" />
      </NavLink>
      <div className="ml-2 md:ml-6 lg:ml-10 xl:ml-16 flex flex-grow justify-between items-end flex-wrap">
        <NavLink
          to={`/patient/account/profile/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 1 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            className="h-8 w-8 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 2 20 20"
          >
            <g>
              <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
            </g>
          </svg>
        </NavLink>
        <NavLink
          to={`/patient/treatment/today/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 2 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            className="h-8 w-8 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-50 -10 550 550"
          >
            <g>
              <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
            </g>
          </svg>
        </NavLink>
        <NavLink
          to={`/patient/treatment-plan/view/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 3 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            className="h-8 w-8 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
          </svg>
        </NavLink>
        <NavLink
          to={`/patient/schedule/schedule-appointment/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 4 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            className="h-8 w-8 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-20 -10 540 540"
          >
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" />
          </svg>
        </NavLink>
        <NavLink
          to={`/patient/payment/make-payment/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 5 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            className="h-8 w-8 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
          </svg>
        </NavLink>
        <NavLink
          to={`/patient/presentation/overview/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 6 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="170 220 270 350"
            className="h-8 w-8 m-auto"
          >
            <path d="m135 492.61c0-29.619 0.17213-32.952 2.0093-38.9 5.1277-16.602 18.467-28.13 36.87-31.864l6.3793-1.2946 23.617 24.954 26.624-26.19 12-1.1426c26.66-2.5384 55.834-12.683 77.71-27.021 11.193-7.3364 13.663-8.4212 19.361-8.5036 5.8995-0.0853 8.9458 1.5438 13.113 7.0122 2.5244 3.3128 2.8135 4.4818 2.787 11.271-0.0452 11.593-4.6985 18.177-17.259 24.422-3.6912 1.835-10.829 5.6323-15.863 8.4384-23.75 13.241-49.589 25.138-61.349 28.247l-6.5 1.7182-0.5289 61.249h-118.97zm182-35.218c2.475-1.4235 8.4285-4.5681 13.23-6.988l8.73-4.3998h127.04v-171h-226.77l-0.38916 23.44c-0.21404 12.892-0.65433 23.705-0.97841 24.029s-3.8013-1.2626-7.7271-3.526l-7.1378-4.1153 4e-3 -22.164c4e-3 -24.735 0.42433-26.515 7.2371-30.669 3.2038-1.9534 5.2783-1.9872 122.26-1.9893 111.32-2e-3 119.22 0.11064 122.43 1.7463 1.8859 0.96165 4.3609 3.0189 5.5 4.5717 2.0591 2.8068 2.0711 3.3535 2.0711 94.177 0 91.189-4e-3 91.358-2.0964 94.177-1.153 1.5529-3.178 3.3793-4.5 4.0587-1.8488 0.95014-20.984 1.2359-82.904 1.238l-80.5 3e-3 4.5-2.5882zm-122.5-51.713c-12.057-3.0793-21.313-10.838-26.835-22.495-2.8619-6.0408-3.1645-7.5882-3.1645-16.18 0-8.6092 0.2994-10.132 3.1931-16.241 14.535-30.684 57.741-30.67 72.127 0.0227 2.3746 5.0664 2.6801 6.9172 2.6801 16.239 0 9.8432-0.20882 10.945-3.2413 17.105-8.0876 16.428-27.748 25.893-44.759 21.549zm92.934-30.227c-2.0152-3.6034-3.4997-6.6712-3.2989-6.8173 0.20073-0.14609 10.265-6.2677 22.365-13.604s23.073-14.168 24.385-15.183c3.6295-2.8074 7.563-2.325 11.862 1.4546 5.2753 4.6377 26.17 20.732 27.656 21.302 0.67771 0.26006 7.9778-5.2813 16.223-12.314 8.2447-7.0329 16.509-14.065 18.365-15.627l3.3751-2.8396-10.645-9.3856 16.274-1.7501c8.9506-0.96255 16.483-1.5404 16.74-1.2841 0.25629 0.25629 0.1366 7.7345-0.26599 16.618l-0.73199 16.152-10.558-10.426-22.311 18.664c-12.271 10.265-23.418 18.942-24.77 19.281-3.0781 0.77256-3.6037 0.43851-21.955-13.955l-15-11.765-21.62 14.015c-11.891 7.7081-21.801 14.015-22.022 14.015s-2.0513-2.9483-4.0665-6.5517z"></path>
          </svg>
        </NavLink>
        <NavLink
          to={`/patient/search/individual-match/${props.id}`}
          className={`ml-2 mb-2 rounded-full h-12 w-12 flex focus:outline-none ${
            props.tab === 7 ? "bg-accent-1" : "bg-background-8"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="h-6 w-6 m-auto"
          >
            <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
          </svg>
        </NavLink>
      </div>
    </nav>
  );
}
