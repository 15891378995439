import { React, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from './views/Home';
import Dashboard from './views/Dashboard';
import AdminProfile from './views/AdminProfile';
import AdminProcedure from './views/AdminProcedure';
import AdminMedication from './views/AdminMedication';
import AdminProducts from './views/AdminProducts';
import AdminPayment from './views/AdminPayment';
import AdminDocuments from './views/AdminDocuments';
import AdminSupport from './views/AdminSupport';
import PatientNew from './views/PatientNew';
import PatientProfile from './views/PatientProfile';
import PatientHealthHistoryMedication from './views/PatientHealthHistoryMedication';
import PatientHealthHistoryTreatment from './views/PatientHealthHistoryTreatment';
import PhysicalMedicalExam from './views/ExamPhysicalMedical';
import BloodWorkExam from './views/ExamBloodWork';
import HairLossClassExam from './views/ExamHairLossClass';
import PatientPayment from './views/PatientPayment';
import PatientMakePayment from './views/PatientMakePayment';
import PatientViewPayment from './views/PatientViewPayment';
import PatientScheduleAppointment from './views/PatientScheduleAppointment';
import PatientViewSchedule from './views/PatientViewSchedule';
import HairDensityTracking from './views/HairDensityTracking';
import HairThicknessTracking from './views/HairThicknessTracking';
import HairOveriew from './views/HairOverview';
import GlobalImages from './views/GlobalImages';
import CloseUpHairImages from './views/CloseUpHairImages';
import TodaysTreatment from './views/TodaysTreatment';
import HistoryTreatment from './views/HistoryTreatment';
import PatientHealthInfo from './views/PatientHealthInfo';
import PatientSearch from './views/PatientSearch';
import IndividualMatch from './views/IndividualMatch';
import GroupSearch from './views/GroupSearch';
import NotFound from './views/NotFound';
import HairData from './views/HairData';
import Dropdown from './components/Dropdown';
import TreatmentPlan from './views/TreatmentPlan';
import PatientReferrals from './views/PatientReferrals';
import GroReport from './views/GroReport';
import Signup from './views/Signup';
import Notifications from "./views/Notifications";
import AdminAccessAccount from './views/AdminAccessAccount';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function App() {
  const [loggedIn, setLoggedIn] = useState(
    new Date().getTime() < localStorage.getItem('expires_at')
  );

  useEffect(() => {
    if (!loggedIn) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_at');
    }
  }, [loggedIn]);

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <div className="min-h-screen relative flex flex-col bg-background-1 text-text-1 font-font-1 font-bold tracking-wide">
          <Switch>
            <Route exact path="/patient/search/individual-match/:id">
              {loggedIn ? <IndividualMatch /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/search/group-search/:id">
              {loggedIn ? <GroupSearch /> : <Redirect to="/" />}
            </Route>
            <Route
              exact
              path="/patient/presentation/gro-tracker/hair-density-tracking/:id"
            >
              {loggedIn ? <HairDensityTracking /> : <Redirect to="/" />}
            </Route>
            <Route
              exact
              path="/patient/presentation/gro-tracker/hair-thickness-tracking/:id"
            >
              {loggedIn ? <HairThicknessTracking /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/presentation/overview/:id">
              {loggedIn ? <HairOveriew /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/gro_report/:id">
              {loggedIn ? <GroReport /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/schedule/schedule-appointment/:id">
              {loggedIn ? <PatientScheduleAppointment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/schedule/view-schedule/:id">
              {loggedIn ? <PatientViewSchedule /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/presentation/hair-data/:id">
              {loggedIn ? <HairData /> : <Redirect to="/" />}
            </Route>
            <Route
              exact
              path="/patient/presentation/hair-images/global-images/:id"
            >
              {loggedIn ? <GlobalImages /> : <Redirect to="/" />}
            </Route>
            <Route
              exact
              path="/patient/presentation/hair-images/close-up-hair-images/:id"
            >
              {loggedIn ? <CloseUpHairImages /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/payment/view-payment-history/:id">
              {loggedIn ? <PatientViewPayment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/payment/make-payment/:id">
              {loggedIn ? <PatientMakePayment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/treatment-plan/view/:id">
              {loggedIn ? <TreatmentPlan /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/treatment/today/:id">
              {loggedIn ? <TodaysTreatment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/treatment/history/:id">
              {loggedIn ? <HistoryTreatment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/account/profile/:id">
              {loggedIn ? <PatientProfile /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/dd">
              <Dropdown />
            </Route>
            <Route exact path="/patient/account/health-info/:id">
              {loggedIn ? <PatientHealthInfo /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/:id/health_history_medication">
              {loggedIn ? (
                <PatientHealthHistoryMedication />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/patient/:id/health_history_treatment">
              {loggedIn ? (
                <PatientHealthHistoryTreatment />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/patient/account/payment/:id">
              {loggedIn ? <PatientPayment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/account/exam/:id/physical_medical">
              {loggedIn ? <PhysicalMedicalExam /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/account/exam/:id/blood_work_tests">
              {loggedIn ? <BloodWorkExam /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/account/exam/:id/hair_loss_class">
              {loggedIn ? <HairLossClassExam /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/new">
              {loggedIn ? <PatientNew /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/existing">
              {loggedIn ? <PatientSearch /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/patient/referrals">
              {loggedIn ? <PatientReferrals /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/profile">
              {loggedIn ? <AdminProfile /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/procedure">
              {loggedIn ? <AdminProcedure /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/medication">
              {loggedIn ? <AdminMedication /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/products">
              {loggedIn ? <AdminProducts /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/payment">
              {loggedIn ? <AdminPayment /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/documents">
              {loggedIn ? <AdminDocuments /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/support">
              {loggedIn ? <AdminSupport /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/admin/notifications">
              {loggedIn ? <Notifications /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/dashboard">
              {loggedIn ? <Dashboard /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/new-clinic-sign-up">
              {loggedIn ? (
                <Redirect to="/dashboard" />
              ) : (
                <Signup setLoggedIn={setLoggedIn} />
              )}
            </Route>
            <Route exatc path="/admin/access_account">
              <AdminAccessAccount setLoggedIn={setLoggedIn} />
            </Route>
            <Route exact path="/">
              {loggedIn ? (
                <Redirect to="/dashboard" />
              ) : (
                <Home setLoggedIn={setLoggedIn} />
              )}
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </Router>
    </Elements>
  );
}
