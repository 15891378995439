import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import PatientNavbar from '../components/PatientNavbar';
import PresentationNavbar from '../components/PresentationNavbar';
import dayjs from 'dayjs';
// import GroIndex from "../views/Overview/GroIndex";
// import GroMeasure from "../views/Overview/GroMeasure";
// import TreatmentProgress from "../assets/images/overview/treatmentProgress.jpg";
// import HomeCareProgress from "../assets/images/overview/homeCareProgress.jpg";
import Loading from './Loading';
import OverviewProgress from '../components/OverviewProgress';
import { useLocation } from 'react-router-dom';
import ShowInvalidReason from '../components/ShowInvalidReason';
// import hairOverviewFront from "../assets/images/hairOverviewFront.png";

const HairOverview = () => {
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [viewMode, setViewMode] = useState('global');
  const [indexMode, setIndexMode] = useState('density');
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [currentId, setCurrentId] = useState('-1');
  const stackedData = [-100, null, null, 0, null, null, 100];
  const [image, setImage] = useState('');
  const [imageStatus, setImageStatus] = useState(false);
  const [hairLineImage, sethairLineImage] = useState('');
  const [treatmentProgress, setTreatmentProgress] = useState(42);
  const [showRawImage, setshowRawImage] = useState(true);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const [referralStatus, setReferralStatus] = useState('');
  const [mlImage, setMlImage] = useState("");
  const [invalidateReason, setInvalidateReason] = useState("");
  const [xCoordinate, setXCoordinate] = useState(0);

  const location = useLocation();
 

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const analysis_id = searchParams.get('analysis_id');
    const mainType = searchParams.get('mainType');
    const subType = searchParams.get('subType');
    
    if(analysis_id &&  mainType && subType){
      const index = hairAnalysis.findIndex((analysis) => {
        return analysis.id === parseInt(analysis_id);
      });

      setCurrentId(index);
      if(mainType === 'portrait'){
        setViewMode('global');
      }else{
        setViewMode(subType);
      }
    }
  },[location, hairAnalysis])

  const chartColor = [
    '#EF8679',
    '#F7A79D',
    '#F7D2CE',
    '#FFFFFF',
    '#B4F8F2',
    '#3DE2D2',
    '#1D968B',
  ];

  const getImagePath = (array, subType, paint = null) => {
    for (var index = 0; index < array.length; index++) {
      if (paint) {
        if (array[index].subType === subType && array[index].paint === paint) {
         if(array[index].status === 'invalidated'){
            if(array[index]?.reason){
              setInvalidateReason(array[index].reason)
            }
            return [array[index].image_path, array[index].status];
          }
          return [array[index].image_path, array[index].status];
        }
      } else {
        if (array[index].subType === subType) {
         if(array[index].status === 'invalidated'){
            if(array[index]?.reason){
              setInvalidateReason(array[index].reason)
            }
            return [array[index].image_path, array[index].status];
          }
          return [array[index].image_path, array[index].status];
        }
      }
    }
    return [];
  };

  const { id } = useParams();
  useEffect(() => {
    //   fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //     },
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       if (response.success) {
    //         setFirstName(response.firstName);
    //         setLastName(response.lastName);
    //       } else {
    //         setMessage(response.message);
    //       }
    //       setReady(true);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       setMessage("Some Error Occured. Please Try Again Later");
    //     });

    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setHairAnalysis(response.hair_analysis);
          setCurrentId(0);
          setPatientReferrals(response.referral);
          if (response.referral) {
            setReferralStatus(response.referral.status);
          }
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      });

    fetch(
      `${process.env.REACT_APP_API}/patient/treatment-progress?patient_id=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
      .then(response => response.json())
      .then(response => {
        setTreatmentProgress(response?.data?.percentage);
      })
      .catch(err => {
        setMessage('Some Error Occured. Please Try Again Later');
      });
  }, [id]);

  useEffect(() => {
    // setViewMode("global");
    if (hairAnalysis && hairAnalysis[currentId]) {
      setIndexMode('density');
      setImage(getImagePath(hairAnalysis[currentId].images.crop, 'front')[0]);
      sethairLineImage(
        getImagePath(hairAnalysis[currentId].images.hair_line, 'front')[0]
      );
    }
  }, [currentId, hairAnalysis]);

  // TODO: May be unnecessary code
  // useEffect(() => {
  //   if (hairAnalysis[0]) {
  //     setImage(
  //       getImagePath(hairAnalysis[0].images.closeup, "frontal")
  //     );
  //   }
  // }, [hairAnalysis]);

  // NOTE: Enable it to reset raw image showing
  // useEffect(() => {
  //   setshowRawImage(true);
  // }, [viewMode]);

  const toggleRawImage = () => {
    setshowRawImage(!showRawImage);
  };

  useEffect(() => {
    let imageArray = [];
    let imageArrayML = [];
    let position = viewMode;
    if (hairAnalysis && hairAnalysis[currentId]) {
      if (viewMode === 'global') {
        imageArray = hairAnalysis[currentId].images.portrait;
        imageArrayML = hairAnalysis[currentId].images.api;
        position = 'crown';
      } else {
        imageArray =  hairAnalysis[currentId].images.closeup;
        imageArrayML = hairAnalysis[currentId].images.closeup_api;
      }
      if (viewMode === 'back') {
        position = 'occipital';
      }
      const imagePathAndStatus = getImagePath(
        imageArray,
        position,
        null
      );
      setImage(imagePathAndStatus[0]);
      setImageStatus(imagePathAndStatus[1]);

      let temp = imageArray.find((prev) => {
        return prev.subType === position;
      });
      if(temp){
        setXCoordinate(temp.scalp_position.x_start);
      }

      const mlImagePathAndStatus = getImagePath(
        imageArrayML,
        position,
        viewMode === "global" ? 2 : 3
      );
      setMlImage(mlImagePathAndStatus[0]);
    }
  }, [showRawImage, hairAnalysis, currentId, viewMode]);

  const setReferralValue = value => {
    if (value === '') {
      value = 'none';
    }
    if (!window.confirm('Are you sure you want to approve the referral?'))
      return setReferralStatus('pending');
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/client_referral_approve`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify({ status: value }),
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          alert(`${response.message}`);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="mt-6">
          <div>
            {patientReferrals ? (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
              </h1>
            ) : (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName}`}
              </h1>
            )}
            <div className="flex items-center md:justify-evenly flex-col md:flex-row">
              <div className=" flex items-center">
                <button
                  className="font-bold text-2xl focus:outline-none"
                  onClick={function () {
                    if (currentId > 0) {
                      setCurrentId(currentId - 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill="#bfc5ca"
                      d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                    />
                  </svg>
                </button>
                <p className="mx-4 text-text-8">
                  {hairAnalysis[currentId]
                    ? dayjs
                        .unix(hairAnalysis[currentId].created_at)
                        .format('MM-DD-YY')
                    : ''}
                </p>
                <button
                  className="font-bold text-2xl focus:outline-none"
                  onClick={function () {
                    if (currentId + 1 < hairAnalysis.length) {
                      setCurrentId(currentId + 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill=" #bfc5ca"
                      d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className="p-8 mt-4 bg-background-5 overflow-hidden"
            style={{ height: 'var(--height-1)' }}
          >
            {patientReferrals != null && (
              <div className="flex justify-end">
                <select
                  disabled={referralStatus === 'accepted'}
                  value={referralStatus}
                  onChange={e => {
                    setReferralStatus(e.target.value);
                    setReferralValue(e.target.value);
                  }}
                  className="form-select bg-accent-1 text-center text-text-3 block rounded-lg focus:outline-none p-1 w-28 appearance-none"
                >
                  <option className="text-text-6 bg-text-3" value="pending">
                    STATUS
                  </option>
                  <option className="text-text-6 bg-text-3" value="accepted">
                    Accepted
                  </option>
                  {/* <option value="pending" className="text-text-6 bg-text-3">
                    Scheduled
                  </option>
                  <option value="showed" className="text-text-6 bg-text-3">
                    Showed
                  </option> */}
                </select>
              </div>
            )}
            {indexMode === 'density' && (
              <div className="mt-4 grid justify-items-stretch flex items-stretch mx-auto text-text-8 font-font-2">
                <div className="md:absolute lg:absolute self-center justify-self-center w-50">
                  <button
                    className={`${
                      viewMode === 'global' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('global');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.crop,
                      //     "front"
                      //   )
                      // );
                    }}
                  >
                    Global
                  </button>
                  <button
                    className={`${
                      viewMode === 'front' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('front');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "frontal"
                      //   )
                      // );
                    }}
                  >
                    Front
                  </button>
                  <button
                    className={`${
                      viewMode === 'crown' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('crown');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "crown"
                      //   )
                      // );
                    }}
                  >
                    Crown
                  </button>
                  <button
                    className={`${
                      viewMode === 'vertex' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('vertex');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "vertex"
                      //   )
                      // );
                    }}
                  >
                    Vertex
                  </button>
                  <button
                    className={`${
                      viewMode === 'back' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('back');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "back"
                      //   )
                      // );
                    }}
                  >
                    Occipital
                  </button>
                  <button
                    className={`${
                      viewMode === 'left' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('left');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "left"
                      //   )
                      // );
                    }}
                  >
                    Left
                  </button>
                  <button
                    className={`${
                      viewMode === 'right' && 'text-text-7'
                    } focus:outline-none mx-2`}
                    onClick={() => {
                      setViewMode('right');
                      // setImage(
                      //   getImagePath(
                      //     hairAnalysis[currentId].images.closeup,
                      //     "right"
                      //   )
                      // );
                    }}
                  >
                    Right
                  </button>
                </div>
                <div className="self-center justify-self-end text-center w-1/4">
                  <NavLink to={`/patient/gro_report/${id}`}>Gro Report</NavLink>
                </div>
              </div>
            )}

            <div className="mt-10 h-full grid grid-cols-3 gap-4 justify-center items-center">
              <div className="flex items-center justify-center">
              {
                viewMode !== 'global' && 
                <div className="demo-test">
                  <h2 className="text-xl font-bold text-center text-text-2">
                    GRO Index
                  </h2>
                  <div
                    className="mt-4 flex flex-col items-center"
                    style={{ marginLeft: 60 }}
                  >
                    {/* <div className="flex flex-row items-center text-text-8">
                      <button
                        className={`focus:outline-none ${
                          indexMode === "line" && "text-text-7"
                        }`}
                        onClick={() => setIndexMode("line")}
                      >
                        Hair Line index
                      </button>
                      <button
                        className={`ml-8 focus:outline-none ${
                          indexMode === "density" && "text-text-7"
                        }`}
                        onClick={() => setIndexMode("density")}
                      >
                        Hair Density Index
                      </button>
                    </div> */}
                    {indexMode === 'line' && (
                      <div className="flex flex-col mt-8">
                        {stackedData.map((data, index) => {
                          const d =
                            hairAnalysis[currentId] &&
                            hairAnalysis[currentId]?.groindex?.hairLine;
                          let i = 0;
                          if (d > 0 && d < 50) i = 4;
                          else if (d > 50 && d < 99) i = 5;
                          else if (d === 100) i = 6;
                          else if (d === 0 ) i = 3;
                          else if (d < 0 && d > -50) i = 2;
                          else if (d < -50 && d > -99) i = 1;
                          else i = 3;
                          return (
                            <div
                              className="flex flex-row relative hover:text-accent-3"
                              key={index}
                            >
                              <div
                                style={{
                                  backgroundColor: chartColor[index],
                                }}
                                className={`flex items-center justify-center w-16 h-8 text-text-6 font-normal transform border-2 border-transparent ${
                                  i === index &&
                                  'font-bold scale-125 z-10 rounded-lg shadow-strong border-white text-white'
                                } bg-background-3`}
                              >
                                {(index === i && parseFloat(d).toFixed(2)) ||
                                  data}
                              </div>
                              <div
                                className="flex items-center justify-center w-20 h-8 px-3 text-xs leading-none text-center "
                                style={
                                  (i === index && { color: '#ffffff' }) || {}
                                }
                              >
                                {index === 6 && 'True Hair Line '}
                                {index === 3 &&
                                  `${
                                    hairAnalysis[0]
                                      ? dayjs
                                          .unix(hairAnalysis[0]?.created_at)
                                          .format('MM-DD-YY')
                                      : ''
                                  } Baseline`}
                                {index === i && hairAnalysis[currentId]
                                  ? dayjs
                                      .unix(hairAnalysis[currentId]?.created_at)
                                      .format('MM-DD-YY')
                                  : ''}
                              </div>
                              {index === 3 && <span class="dot-white"></span>}
                              {index === 6 && <span class="dot-blue"></span>}
                              {index === i && <span class="dot-beach"></span>}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {indexMode === 'density' && (
                      <div className="flex flex-col mt-8">
                        {stackedData.reverse().map((data, index) => {
                          const d =
                            hairAnalysis[currentId] &&
                            hairAnalysis[currentId]?.groindex?.hairDensity[
                              viewMode
                            ];
                          let i = 6;
                          if (d > 0 && d <= 50) i = 2;
                          else if (d > 50 && d <= 99) i = 1;
                          else if (d > 99) i = 0;
                          else if (d === 0) i = 3;
                          else if (d < 0 && d >= -50) i = 4;
                          else if (d < -50 && d >= -99) i = 5;
                          else if (d < -99) i = 6;
                          else i = 3;

                          return (
                            <div
                              className="flex flex-row relative hover:text-accent-3"
                              key={index}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    chartColor[chartColor.length - 1 - index],
                                }}
                                className={`flex items-center justify-center w-16 h-8 text-text-6 font-normal transform border-2 border-transparent ${
                                  i === index &&
                                  'font-bold scale-125 z-10 rounded-lg shadow-strong border-white text-white'
                                } bg-background-3`}
                              >
                                {d != null ? (index === i && parseFloat(d).toFixed(2)) ||
                                  data : ""}
                                 {(index === i && d == null && <>N/A</>) }
                              </div>
                              <div
                                className="flex items-center justify-center w-20 h-8 px-3 text-xs leading-none text-center "
                                style={
                                  (i === index && { color: '#0eb5f0' }) || {}
                                }
                              >
                                {index === 6 && ' 100% decrease'}
                                {index === 3 &&
                                  ` Baseline ${
                                    hairAnalysis[0]
                                      ? dayjs
                                          .unix(hairAnalysis[0]?.created_at)
                                          .format('MM-DD-YY')
                                      : ''
                                  }`}
                                {index === i &&
                                index !== 3 &&
                                hairAnalysis[currentId]
                                  ? dayjs
                                      .unix(hairAnalysis[currentId]?.created_at)
                                      .format('MM-DD-YY')
                                  : ''}
                                {index === 0 && ' 100% increase'}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              }
              </div>
              <div className="flex items-center justify-center">
                <div className="mx-auto">
                  {imageStatus === 'invalidated' && (
                    <div onClick={toggleRawImage} className='flex justify-center text-justify' style={{ width: '280px' }}><ShowInvalidReason reasons={invalidateReason} /></div>
                  )}
                  {indexMode === "density" && image && (
                    <div className="relative flex justify-center">
                      {
                        ((viewMode === 'global' && showRawImage) || viewMode !== 'global')  && (
                          <img
                            src={`${image}?width=286`}
                            alt=""
                            className="h-96 cursor-pointer object-cover"
                            onClick={toggleRawImage}
                          />
                        )
                      }
                      {
                        !showRawImage && imageStatus !== 'invalid' && imageStatus !== 'invalidated'  &&
                        <img
                          src={`${mlImage}?width=286`}
                          alt=""
                          className={`h-96 cursor-pointer ${(viewMode !== 'global') ? 'absolute object-cover': ''} top-0`}
                          onClick={toggleRawImage}
                          style={{ left: `${xCoordinate/10 + 10}px` }}
                        />
                      }
                    </div>
                  )}
                  {imageStatus === 'invalid' && (
                    <p className='text-center' onClick={toggleRawImage}>Image is being Validated</p>
                  )}
                  {imageStatus === 'approved' && (
                    <p className='text-center' onClick={toggleRawImage}>This image is validated</p>
                  )}
                  {indexMode === 'line' && hairLineImage && (
                    <img src={hairLineImage} alt="" className="h-96" />
                  )}

                  {!image && indexMode === 'density' && (
                    <p>No image uploaded</p>
                  )}
                  {!hairLineImage && indexMode === 'line' && (
                    <p>No image uploaded</p>
                  )}
                  {/* {image} */}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <OverviewProgress
                    title="Treatment Progress"
                    subTitle={`${treatmentProgress}% completed`}
                    progress={treatmentProgress / 100}
                  />
                  <div className="my-3" />
                  <OverviewProgress
                    title="Home Care Progress"
                    subTitle="0 months"
                    progress={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <PresentationNavbar id={id} tab={1} />
      </main>
      <Loading message={'Loading Hair Gro Tracker'} ready={ready} />
    </>
  );
};

export default HairOverview;
