import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../views/Loading";
import PatientNavbar from "../components/PatientNavbar";
import PatientSidebar from "../components/PatientSidebar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function PatientProfile() {
  const { id } = useParams();
  const [examDrop, setExamDrop] = useState(false);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthDropdown, setHealthDropdown] = useState(false);
  const [patient, setPatient] = useState(null);
  const [editPatient, setEditPatient] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const isPatientValid = () => {
    return true;
  };

  const savePatient = () => {
    if (isPatientValid()) {
      setReady(false);
      let patientTemp = editPatient;
      patientTemp.dob = moment(editPatient.dob).format("YYYY-MM-DD");
      fetch(`${process.env.REACT_APP_API}/patient/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editPatient),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            console.log(response);
            setPatient(response.patient);
            setEditPatient({
              ...response.patient,
              address: { ...response.patient.address },
            });
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatient(response.patient);
          setEditPatient({
            ...response.patient,
            address: { ...response.patient.address },
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    if (editPatient && editPatient.dob) {
      let age = moment().diff(moment(editPatient.dob, "MM-DD-YYYY"), "years");
      setEditPatient({
        ...editPatient,
        age: age,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPatient && editPatient.dob]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? "" : "hidden"
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            id={id}
          />
          {patient && (
            <div className="mx-auto max-w-5xl">
              {patientReferrals ? (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${patient.firstName} ${patient.lastName} referred by ${patientReferrals.referred_by}`}
                </h1>
              ) : (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${patient.firstName} ${patient.lastName}`}
                </h1>
              )}
              <div className="mt-4 flex justify-between items-center">
                <button
                  type="button"
                  className="px-8 py-1 bg-accent-1 rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  {editMode ? "Cancel" : "Edit"}
                </button>
                <p className="mx-32 lg:mx-48">PATIENT INFORMATION</p>
                <button
                  className="px-8 py-1 bg-accent-1 rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                  disabled={!editMode}
                  onClick={() => {
                    setEditMode(!editMode);
                    if (editMode) {
                      savePatient();
                    }
                  }}
                >
                  SAVE
                </button>
              </div>
              <form
                className="flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  savePatient();
                }}
              >
                <div className="mx-auto mt-16 flex flex-col items-end">
                  <label className="flex items-center">
                    <p className="ml-2">First Name</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                      value={
                        editMode ? editPatient.firstName : patient.firstName
                      }
                      disabled={!editMode}
                      required
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          firstName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">Last Name</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                      value={editMode ? editPatient.lastName : patient.lastName}
                      disabled={!editMode}
                      required
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          lastName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <div className="mt-4 ml-6 self-start flex items-center md:flex-row">
                    <p>Gender</p>
                    <select
                      value={editMode ? editPatient.gender : patient.gender}
                      onChange={(e) =>
                        setEditPatient({
                          ...editPatient,
                          gender: e.target.value,
                        })
                      }
                      className="ml-9 w-32 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                    >
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                    <label className="mt-4 md:mt-0 md:ml-1 flex items-center">
                      <p className="ml-5">Ethnicity</p>
                      <input
                        type="text"
                        className="ml-8 w-40 md:w-32 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode ? editPatient.ethnicity : patient.ethnicity
                        }
                        disabled={!editMode}
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            ethnicity: event.target.value,
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-4 flex flex-col items-end md:flex-row">
                    <label className="flex items-center">
                      <p className="ml-2">DOB</p>
                      {/* <input
                        type="date"
                        className="ml-6 w-40 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={editMode ? editPatient.dob : patient.dob}
                        disabled={!editMode}
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            dob: event.target.value,
                          });
                        }}
                      /> */}
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        disabled={!editMode}
                        className="ml-6 w-40 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                        selected={
                          editMode
                            ? Date.parse(editPatient.dob)
                            : Date.parse(patient.dob)
                        }
                        onChange={(date) => {
                          // let dateFormat = new Date(date).toISOString();
                          // dateFormat = moment(dateFormat)
                          // dateFormat = dateFormat.utc().format("MM/DD/YYYY");

                          setEditPatient({
                            ...editPatient,
                            dob: date,
                          });
                        }}
                      />
                    </label>
                    <label className="mt-4 md:mt-0 md:ml-5 flex items-center">
                      <p className="ml-5">Age</p>
                      <input
                        type="text"
                        className="ml-6 w-40 md:w-32 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={editMode ? editPatient.age : patient.age}
                        disabled
                        // onChange={event => {
                        //   setEditPatient({
                        //     ...editPatient,
                        //     age: event.target.value,
                        //   });
                        // }}
                      />
                    </label>
                  </div>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">Address</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editPatient.address.street
                          : patient.address.street
                      }
                      disabled={!editMode}
                      placeholder="Street"
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          address: {
                            ...editPatient.address,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                    value={
                      editMode
                        ? editPatient.address.suite
                        : patient.address.suite
                    }
                    disabled={!editMode}
                    placeholder="Suite"
                    onChange={(event) => {
                      setEditPatient({
                        ...editPatient,
                        address: {
                          ...editPatient.address,
                          suite: event.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                    value={
                      editMode ? editPatient.address.city : patient.address.city
                    }
                    disabled={!editMode}
                    placeholder="City"
                    onChange={(event) => {
                      setEditPatient({
                        ...editPatient,
                        address: {
                          ...editPatient.address,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-4 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editPatient.address.state
                            : patient.address.state
                        }
                        disabled={!editMode}
                        placeholder="State"
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            address: {
                              ...editPatient.address,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editPatient.address.zip
                            : patient.address.zip
                        }
                        disabled={!editMode}
                        placeholder="Zip"
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            address: {
                              ...editPatient.address,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">Phone</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                      value={editMode ? editPatient.phone : patient.phone}
                      disabled={!editMode}
                      required
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">Email</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                      value={editMode ? editPatient.email : patient.email}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={"Loading Patient Details"} ready={ready} />
    </>
  );
}
